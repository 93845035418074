
import barba from '@barba/core';
import Swiper from 'swiper';
import Splide from '@splidejs/splide';
import gsap from 'gsap';

export default class {

  constructor() {

    barba.hooks.beforeOnce((data) => {
      this.heroSlider(data.next.container)
      this.interviewSlider(data.next.container)
      this.questionnaireSlider(data.next.container)
      this.requirementsSlider(data.next.container)
    })
    barba.hooks.after((data) => {
      this.heroSlider(data.next.container)
      this.interviewSlider(data.next.container)
      this.questionnaireSlider(data.next.container)
      this.requirementsSlider(data.next.container)
    });

  }

  heroSlider(container) {
    new Swiper('.home-hero__slider', {
      loop: true,
      effect: 'fade',
      speed: 1000,
      allowTouchMove: false,
      simulateTouch: false,
      autoplay: {
        delay: 6000
      },
      touchStartPreventDefault: false,
      touchStartForcePreventDefault: true,
      pagination: {
        el: '.home-hero__slider .swiper-pagination',
        renderBullet: function (index, className) {
          return '<span class="' + className + ' swiper-pagination-bullet--svg-animation"><svg width="60" height="60" viewBox="0 0 60 60"><circle class="svg__circle-outer" cx="30" cy="30" r="20" fill="none" stroke-width="1"></circle><circle class="svg__circle" cx="30" cy="30" r="20" fill="none" stroke-width="2"></circle><circle class="svg__circle-inner" cx="30" cy="30" r="5"></circle></svg></span>';
        },
      },
    })
  }

  interviewSlider(container) {

    const el = container.querySelector('.js-interview__slider')

    if (el) {
      const slide = new Splide('.js-interview__slider', {
        type: 'loop',
        speed: 4000,
        gap: 0,
        fixedWidth: "360px",
        focus: "left",
        pagination: false,
        drag: false,
        autoplay: true,
        interval: 4000,
        arrowPath: "M13.0991 20.2551L16.5082 23.8735C16.6306 24.0253 16.8458 24.043 16.9888 23.913C17.1318 23.783 17.1485 23.5546 17.026 23.4028C17.0146 23.3886 17.0021 23.3754 16.9888 23.3633L14.1627 20.36H27.6591C27.8474 20.36 28 20.198 28 19.9982C28 19.7983 27.8474 19.6363 27.6591 19.6363H14.1627L16.9888 16.6367C17.1318 16.5067 17.1485 16.2783 17.026 16.1265C16.9035 15.9747 16.6883 15.957 16.5453 16.087C16.532 16.0992 16.5196 16.1123 16.5082 16.1265L13.0991 19.7449C12.967 19.886 12.967 20.1139 13.0991 20.2551Z",
        classes: {
          prev: 'home-interview__slider-prev',
          next: 'home-interview__slider-next',
        },
        breakpoints: {
          575: {
            fixedWidth: "72%",
          },
          767: {
            fixedWidth: "42%",
          },
          991: {
            fixedWidth: "36%",
            drag: true,
          }
        }
      });

      slide.on('move', (newIndex, prevIndex, destIndex) => {
        const activeSlide = el.querySelectorAll(".slide-active");
        if (activeSlide.length > 0) {
          activeSlide.forEach(slide => {
            slide.classList.remove('slide-active');
            if (window.innerWidth >= 992) {
              slide.style.width = "360px"; // 初期の幅に戻す
            }
          });
        }
        let nextSlide, cloneFirst, cloneEnd;
        if (newIndex > prevIndex) {
          if (newIndex === slide.length - 1) {
            cloneEnd = el.querySelector('#' + slide.root.id + '-slide01').previousElementSibling;
            cloneEnd.classList.add('slide-active');
            nextSlide = el.querySelector('#' + slide.root.id + '-slide0' + slide.length);
          } else {
            nextSlide = el.querySelector('#' + slide.root.id + '-slide0' + (newIndex + 1));
          }
          setTimeout(() => {
            nextSlide.classList.add('slide-active');
            if (window.innerWidth >= 992) {
              nextSlide.style.width = "50%"; // アクティブなスライドの幅を変更
            }
          }, 1);
        }
        if (newIndex < prevIndex) {
          if (newIndex === 0) {
            cloneEnd = el.querySelector('#' + slide.root.id + '-slide0' + slide.length).nextElementSibling;
            cloneEnd.classList.add('slide-active');
            nextSlide = el.querySelector('#' + slide.root.id + '-slide01');
          } else {
            nextSlide = el.querySelector('#' + slide.root.id + '-slide0' + (newIndex + 1));
          }
          setTimeout(() => {
            nextSlide.classList.add('slide-active');
            if (window.innerWidth >= 992) {
              nextSlide.style.width = "50%"; // アクティブなスライドの幅を変更
            }
          }, 1);
        }
      });
      slide.on('mounted', () => {
        const slides = el.querySelectorAll('.js-interview__slider .splide__slide');
        slides[0].style.width = "50%";
      });

      slide.mount();

    }
  }

  questionnaireSlider(container) {

    const urlParams = new URLSearchParams(window.location.search);
    const source = urlParams.get('source');

    let initialIndex = 0; // デフォルトの最初のアクティブスライドのインデックス

    if (source === 'message') {
      initialIndex = 4; // 特定のリンクから遷移してきた場合のアクティブスライドのインデックス
    }

    const el = container.querySelector('.js-questionnaire__slider');
    if (el) {
      const slide = new Splide('.js-questionnaire__slider', {
        loop: true,
        type: 'fade',
        rewind: true,
        pagination: false,
        drag: false,
        simulateTouch: false,
        speed: 1000,
        autoplay: true,
        interval: 10000,
        pauseOnHover: false,
        start: initialIndex,
        arrowPath: "M13.0991 20.2551L16.5082 23.8735C16.6306 24.0253 16.8458 24.043 16.9888 23.913C17.1318 23.783 17.1485 23.5546 17.026 23.4028C17.0146 23.3886 17.0021 23.3754 16.9888 23.3633L14.1627 20.36H27.6591C27.8474 20.36 28 20.198 28 19.9982C28 19.7983 27.8474 19.6363 27.6591 19.6363H14.1627L16.9888 16.6367C17.1318 16.5067 17.1485 16.2783 17.026 16.1265C16.9035 15.9747 16.6883 15.957 16.5453 16.087C16.532 16.0992 16.5196 16.1123 16.5082 16.1265L13.0991 19.7449C12.967 19.886 12.967 20.1139 13.0991 20.2551Z",
        classes: {
          prev: 'questionnaire__slider-prev',
          next: 'questionnaire__slider-next',
        },
      });

      slide.on('mounted', () => {
        const firstSlide = slide.Components.Elements.slides[0];
        const svgElement = firstSlide.querySelector('svg');

        const comment = svgElement.querySelectorAll('.comment__wrapper');
        const progress = svgElement.querySelectorAll('.progress');
  
        gsap.fromTo(progress, {
          autoAlpha: 1,
          strokeDasharray: 760,
          strokeDashoffset: 760,
          rotate: -90,
          transformOrigin: "center center",
        },{
          strokeDashoffset: 0,
          duration:10,
        });
  
        // タイムラインを作成してアニメーションを定義
        const tl = gsap.timeline();
        tl.fromTo(comment, {
          autoAlpha: 0,
          y: 100,
          scale:0.5,
          transformOrigin: "center center",
        }, {
          autoAlpha: 1,
          y: 0,
          scale:1,
          duration: .4,
          delay:.6,
          stagger: { 
            each: .1,
            from: "random",
          },
        });

        // タイムラインを再生
        tl.play();
      });
  
      slide.mount();
      

      slide.on('active', (slide) => {
        const activeSlide = slide.slide;
        const svgElement = activeSlide.querySelector('svg');

        const comment = svgElement.querySelectorAll('.comment__wrapper');
        const progress = svgElement.querySelectorAll('.progress');
  
        gsap.fromTo(progress, {
          autoAlpha: 1,
          strokeDasharray: 760,
          strokeDashoffset: 760,
          rotate: -90,
          transformOrigin: "center center",
        },{
          strokeDashoffset: 0,
          duration:10,
        });
  
        // タイムラインを作成してアニメーションを定義
        const tl = gsap.timeline();
        tl.fromTo(comment, {
          autoAlpha: 0,
          y: 100,
          scale:0.5,
          transformOrigin: "center center",
        }, {
          autoAlpha: 1,
          y: 0,
          scale:1,
          duration: .4,
          delay:.6,
          stagger: { 
            each: .1,
            from: "random",
          },
        });

        // タイムラインを再生
        tl.play();
      });

      slide.on('inactive', (slide) => {
        const inactiveSlide = slide.slide;
        const svgElement = inactiveSlide.querySelector('svg');

        const comment = svgElement.querySelectorAll('.comment__wrapper');
        const progress = svgElement.querySelectorAll('.progress');
  
        // GSAPアニメーションを使用してSVGを初期値に戻す
        gsap.to(progress, {
          autoAlpha: 1,
          strokeDasharray: 760,
          strokeDashoffset: 760,
          rotate: -90,
          transformOrigin: "center center",
        });
        gsap.to(comment, {
          autoAlpha: 0,
          y: 100,
          scale:0.5,
          transformOrigin: "center center",
        });

      });
    }
  }

  requirementsSlider(container) {
    const el = container.querySelector('.js-requirements__slider');
    const thumbSlider = container.querySelector('.js-thumb-slider');

    if (el && thumbSlider) {
      const requirementsSlide = new Splide('.js-requirements__slider', {
        type: 'fade',
        rewind: true,
        pagination: false,
        arrowPath: "M13.0991 20.2551L16.5082 23.8735C16.6306 24.0253 16.8458 24.043 16.9888 23.913C17.1318 23.783 17.1485 23.5546 17.026 23.4028C17.0146 23.3886 17.0021 23.3754 16.9888 23.3633L14.1627 20.36H27.6591C27.8474 20.36 28 20.198 28 19.9982C28 19.7983 27.8474 19.6363 27.6591 19.6363H14.1627L16.9888 16.6367C17.1318 16.5067 17.1485 16.2783 17.026 16.1265C16.9035 15.9747 16.6883 15.957 16.5453 16.087C16.532 16.0992 16.5196 16.1123 16.5082 16.1265L13.0991 19.7449C12.967 19.886 12.967 20.1139 13.0991 20.2551Z",
        classes: {
          prev: 'blog__requirements__slider-prev',
          next: 'blog__requirements__slider-next',
        },
      }).mount();

      const thumbSlide = new Splide('.js-thumb-slider', {
        fixedWidth: 100,
        fixedHeight: 60,
        gap: 10,
        rewind: true,
        pagination: false,
        arrows: false,
        cover: true,
        isNavigation: true,
        focus: "left",
      });

      thumbSlide.sync(requirementsSlide).mount();
    }
  }
}