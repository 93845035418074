import barba from '@barba/core';
import gsap from 'gsap';

export default class {
    constructor() {
        barba.hooks.afterOnce((data) => {
            this.init();
        });
        barba.hooks.after((data) => {
            this.init();
        });
    }
    
    init() {
        const homeHero = document.querySelector("#home-hero");

        if (homeHero) {
            let heroPrimary = document.querySelectorAll('.home-hero__text__ja');
            if (heroPrimary.length > 0) {
                heroPrimary.forEach((h) => {
                    let pr_str = h.textContent.split("");
                    let str = "";
                    pr_str.forEach((s) => {
                        str += "<span class='home-hero__text__ja-str'>" + s + "</span>";
                    });
                    h.innerHTML = str;
                });
            }

            let heroSecondary = document.querySelectorAll('.home-hero__text__en');
            if (heroSecondary.length > 0) {
                heroSecondary.forEach((h) => {
                    let sc_str = h.textContent.split("");
                    let str = "";
                    sc_str.forEach((s) => {
                        str += "<span class='home-hero__text__en-str'>" + s + "</span>";
                    });
                    h.innerHTML = str;
                });
            }

            gsap.set('.home-hero__text__ja .home-hero__text__ja-str', {
                opacity: 0,
                y: 10,
            })
            gsap.set('.home-hero__text__ja .home-hero__text__ja-str', {
                opacity: 0,
                y: 10,
            })
            gsap.set('.home-hero__slider--wrapper', {
                scale: 1.2,
            })
            setTimeout(function() {
                gsap.timeline()
                    .to('.home-hero__slider--wrapper', {
                        scale: 1,
                        duration: 2,
                        ease: "expo.inOut",
                    })
                    .to('.home-hero__text__ja .home-hero__text__ja-str', {
                        opacity: 1,
                        y: 0,
                        duration: .8,
                        ease: "power2.inOut",
                        stagger: {
                            each: 0.08,
                            from: "start"
                        },
                        onStart: function() {
                            var jaElements = document.querySelectorAll('.home-hero__text__ja');
                            jaElements.forEach(function(element, index) {
                                gsap.to(element, {
                                    className: "home-hero__text__ja home-hero__text__line"
                                });
                            });
                        }
                    }, "-=0.8")
                    .to('.home-hero__text__en .home-hero__text__en-str', {
                        opacity: 1,
                        y: 0,
                        duration: 0.8,
                        delay: 0.8,
                        ease: "power2.inOut",
                        stagger: {
                            each: 0.04,
                            from: "start"
                        }
                    }, "-=1");
            }, 800);
        }
    }
}
