import barba from '@barba/core';
import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";
import lottie from "lottie-web";

gsap.registerPlugin(ScrollTrigger);

export default class{

	constructor(){
        barba.hooks.once((data) => {
            this.run(document)
            this.lottie(data.next.container)
        });
        barba.hooks.before(() => {
            ScrollTrigger.killAll();
        });
        barba.hooks.after((data) => {
            this.lottie(data.next.container)
            this.run(data.next.container)
        });
    }

    lottie(container) {

        const homeAboutCards = document.querySelectorAll('.home-about__card');

        if (homeAboutCards) {
            homeAboutCards.forEach((card) => {
                const lottieIco = card.querySelector('.home-about__card--ico');
                const lottieLink = card.querySelector('.home-about__card--link');
                const lottiePath = lottieIco.getAttribute('data-about-ico');

                if (lottieIco && lottieLink && lottiePath) {
                    let lottieObj = lottie.loadAnimation({
                        container: lottieIco,
                        renderer: 'svg',
                        loop: true,
                        autoplay: false,
                        path: "/dist/img/lottie/" + lottiePath + ".json"
                    });

                    lottieObj.addEventListener("DOMLoaded", function () {
                        const svgPaths = lottieIco.querySelectorAll('path');
                        const initialColors = [];

                        // 初期の色を保存
                        svgPaths.forEach((path) => {
                            const computedStyle = window.getComputedStyle(path);
                            initialColors.push(computedStyle.getPropertyValue('stroke'));
                        });

                        lottieLink.addEventListener("mouseenter", function () {
                            lottieObj.setDirection(1);
                            lottieObj.play();

                            // ホバー時の色に変更
                            svgPaths.forEach((path, index) => {
                                path.style.stroke = 'rgb(241, 241, 241)'; 
                            });
                        });

                        lottieLink.addEventListener("mouseleave", function () {
                            lottieObj.stop();

                            // 初期の色を復元
                            svgPaths.forEach((path, index) => {
                                path.style.stroke = initialColors[index];
                            });
                        });
                    });
                }

            });
        }
    }
    
    run(container){

        this.conceptOrnament = document.querySelector(".home-concept__ornament--str");
        this.conceptMask = document.querySelector(".home-concept__mask");
        this.entrySticky = document.querySelector(".home-entry__link");

        if(this.conceptOrnament) {
            gsap.set(this.conceptOrnament, {
                y:0,
            });
            gsap.to(this.conceptOrnament, {
                y:-500,
                scrollTrigger: {
                    trigger: this.conceptOrnament,
                    start: "top bottom",
                    scrub: 1,
                    // markers: true,
                },
            });
        }
        if(this.conceptMask) {
            ScrollTrigger.matchMedia({
                "(min-width: 992px)": () => {
                    gsap.set(this.conceptMask, {
                        y: "-20%",
                        x: "-15%",
                        webkitMaskPosition: '0% 100%',
                        webkitMaskSize: '100% 0%',
                        rotate: "20",
                    });
                    gsap.to(this.conceptMask, {
                        webkitMaskSize: '100% 160%',
                        duration: 3,
                        ease: "power2.inOut",
                        scrollTrigger: {
                            trigger: ".home-concept__wrapper",
                            start: "top top",
                            // markers: true,
                        },
                    });
                }
            }) 
            ScrollTrigger.matchMedia({
                "(max-width: 991px)": () => {
                    gsap.set(this.conceptMask, {
                        y: "0%",
                        x: "0%",
                        webkitMaskPosition: '0% 100%',
                        webkitMaskSize: '100% 0%',
                        rotate: "0",
                    });
                    gsap.to(this.conceptMask, {
                        webkitMaskSize: '100% 180%',
                        duration: 3,
                        ease: "power2.inOut",
                        scrollTrigger: {
                            trigger: ".home-concept__wrapper",
                            start: "top top",
                        },
                    });
                }
            }) 
        }

        if(this.entrySticky) {
            ScrollTrigger.matchMedia({
                "(min-width: 768px)": () => {
                    gsap.to(this.entrySticky, {
                        scrollTrigger: {
                            trigger: this.entrySticky,
                            endTrigger: ".home-entry__wrapper",
                            start: "center center",
                            end: "bottom bottom" ,
                            pin: true,
                            // markers: true,
                        },
                    });
                }
            }) 
        }
    }
}