import gsap from "gsap";
import anime from 'animejs';

export default class {
    /**
     * loading要素の初期設定等を行うコンストラクタ
     * DOM生成時に即時実行
     */
    constructor() {

        this.isFirstSession = sessionStorage.getItem("isFirstSession");

        this.mask = document.querySelector('#js-loading__mask')
        this.first = "#js-loading__mask .js-loading__first"
        this.second = "#js-loading__mask .js-loading__second"
        this.logoAnimation_elm = document.querySelector('#js-loading__logo')
        if (this.mask) {
            this.mask.style.display = "block"
        }

        if (process.env.MIX_DISP_LOADING == "false" && process.env.NODE_ENV == "development") {
            if (this.mask) {
                this.mask.style.display = "none"
            }
        }
    }
    /**
     * 初期表示前に1度だけ実行
     * @param {*} data 
     */
    beforeOnce(data) {
        const self = this;
        return new Promise(resolve => {
            if (data.next.namespace == "not-loading") {
                resolve()
            }
            if (this.isFirstSession === null || this.isFirstSession === "true") {
            let timeline = gsap.timeline();
            timeline
                .to(
                    '#js-loading__random--str',
                    {
                        opacity: 0,
                        delay: 0,
                        duration: .1,
                        onStart: () => {
                            const randomStrElements = document.querySelectorAll('.js-loading__str');
                            const randomIndex = Math.floor(Math.random() * randomStrElements.length) || 1;
                            randomStrElements[randomIndex].style.display = 'block';
                        }
                    }
                ).fromTo(
                    '#js-loading__logo',
                    {
                        opacity: 0,
                    },
                    {
                        opacity: 1,
                        delay: .4,
                        duration: .4,
                    }
                ).to(
                    '#js-loading__logo',
                    {
                        opacity: 0,
                        delay: .4,
                        duration: .4,
                    }
                ).to(
                    '#js-loading__random--str',
                    {
                        opacity: 1,
                        delay: .4,
                        duration: .4,
                    }
                ).to(
                    '#js-loading__random--str',
                    {
                        opacity: 0,
                        delay: 1,
                        duration: .4,
                        onComplete: () => {
                            setTimeout(() => {
                                this.initialScroll();
                            }, 1000);
                            resolve();
                        }
                    }
                );
            }else {
                resolve();
            }
        })
    }
    /**
     * 初期表示時に1度だけ実行
     * @param {*} data 
     */
    once(data) {
        return new Promise(resolve => {
            resolve();
        })
    }
    /**
     * 初期表示完了後に1度だけ実行
     * @param {*} data 
     */
    afterOnce(data) {

        return new Promise(resolve => {

            if (data.next.namespace == "not-loading") {
                resolve();
            }

            const timelime = anime.timeline()
            timelime
                .add({
                    targets: this.first,
                    translateY: [0, "-120%"],
                    easing: 'easeOutCubic',
                    duration: 600
                }, 200)
                .add({
                    targets: this.second,
                    translateY: [0, "-120%"],
                    easing: 'easeInCubic',
                    duration: 600,
                }, '-=400')
                .add({
                    targets: this.logoAnimation_elm,
                    translateY: [0, "-200%"],
                    opacity: [0, 0],
                    easing: 'easeInExpo',
                    duration: 1000,
                    complete: () => {
                        this.mask = document.querySelector('#js-loading__mask')
                        if (this.mask) {
                            this.mask.style.display = "none"
                        }
                        resolve();
                    }
                    // 初期読み込み時の待ち時間
                }, 1000)
        });
    }
    /**
     * 次ページ読み込み前に実行
     * @param {*} data 
     */
    beforeLeave(data) {
        return new Promise(resolve => {
            resolve();
        })
    }
    /**
     * 次ページ読み込み時に実行
     * @param {*} data 
     */
    leave(data) {

        return new Promise(resolve => {
            this.mask = document.querySelector('#js-loading__mask')
            if (this.mask) {
                this.mask.style.display = "block"
            }

            if (data.next.namespace == "not-loading") {
                resolve();
            }

            data.next.container.style.opacity = 0;
            const timelime = anime.timeline()
            timelime

                .add({
                    targets: this.first,
                    translateY: ["100%", 0],
                    easing: 'easeInCubic',
                    duration: 500,
                    complete: () => {
                        resolve();
                    }
                })

        });
    }
    /**
     * 次ページ読み込み完了後に実行
     * @param {*} data 
     */
    afterLeave(data) {
        return new Promise(resolve => {
            resolve();
        })
    }
    /**
     * 次ページ表示前に実行
     * @param {*} data 
     */
    beforeEnter(data) {
        return new Promise(resolve => {
            setTimeout(() => {
                this.initialScroll();
                resolve();
            }, 500);
            resolve();
        })
    }
    /**
     * 次ページ表示時に実行
     * @param {*} data 
     */
    enter(data) {
        return new Promise(resolve => {
            resolve();
        })
    }
    /**
     * 次ページ表示完了後に実行
     * @param {*} data 
     */
    afterEnter(data) {
        return new Promise(resolve => {
            resolve();
        })
    }
    /**
     * すべて完了後に実行
     * @param {*} data 
     */
    after(data) {

        return new Promise(resolve => {


            if (data.next.namespace == "not-loading") {
                this.initialScroll()
                data.next.container.style.opacity = 1;
                resolve();
            }


            const timelime = anime.timeline()
            timelime
                .add({
                    targets: this.first,
                    translateY: [0, "-120%"],
                    easing: 'easeOutCubic',
                    duration: 600,
                    begin: () => {
                        this.initialScroll()
                        data.next.container.style.opacity = 1;
                    }
                }, 200)
                .add({
                    targets: this.second,
                    translateY: [0, "-120%"],
                    easing: 'easeInCubic',
                    duration: 600,
                    complete: () => {

                        this.mask = document.querySelector('#js-loading__mask')
                        if (this.mask) {
                            this.mask.style.display = "none"
                        }
                        resolve();
                    }
                }, '-=400')


        });
    }
    initialScroll() {
        if (location.hash == "") {
            window.scrollTo(0, 0);
        } else {
            let digit = location.hash.replace(/^#/, "");
            let anchor = document.getElementById(digit);
            if (!anchor) return;

            if (anchor) {
                const rect = anchor.getBoundingClientRect();
                const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                let top = rect.top + scrollTop;
                const header = document.querySelector('[data-header]');
                if (header) {
                    if (header) {
                        top = top - header.clientHeight;
                    }
                }
                window.scrollTo(0, top);
            }

        }
    }
}