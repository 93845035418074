import barba from '@barba/core';
import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";
import lottie from "lottie-web";

gsap.registerPlugin(ScrollTrigger);

export default class {

    constructor() {
        barba.hooks.once((data) => {
            this.run(data.next.container)
        });
        barba.hooks.before(() => {
            ScrollTrigger.killAll();
        });
        barba.hooks.after((data) => {
            this.run(data.next.container)
        });
    }

    run(container) {

        const numbersCards = document.querySelectorAll('.innumbers__card');

        if (numbersCards) {
            numbersCards.forEach((card) => {

                const historyImage = card.querySelector('.innumbers__image');

                if(historyImage){
                    ScrollTrigger.matchMedia({
                        "(min-width: 1200px)": () => {
                            gsap.set(historyImage, {
                                x:"-7%",
                            });
                            gsap.to(historyImage, {
                                x:"-14%",
                                duration:3,
                                scrollTrigger: {
                                    trigger: card,
                                    start: "center center+=20%",
                                    once: true
                                }
                            });
                        }
                    });
                    ScrollTrigger.matchMedia({
                        "(min-width: 992px) and (max-width: 1199px)": () => {
                            gsap.set(historyImage, {
                                x:"-7%",
                            });
                            gsap.to(historyImage, {
                                x:"-18%",
                                duration:3,
                                scrollTrigger: {
                                    trigger: card,
                                    start: "center center+=20%",
                                    once: true
                                }
                            });
                        }
                    });
                    ScrollTrigger.matchMedia({
                        "(min-width: 768px) and (max-width: 991px)": () => {
                            gsap.set(historyImage, {
                                x:"-20%",
                            });
                            gsap.to(historyImage, {
                                x:"-25%",
                                duration:3,
                                scrollTrigger: {
                                    trigger: card,
                                    start: "center center+=20%",
                                    scrub:1,
                                }
                            });
                        }
                    });
                    ScrollTrigger.matchMedia({
                        "(min-width: 576px) and (max-width: 767px)": () => {
                            gsap.set(historyImage, {
                                x:"-10%",
                            });
                            gsap.to(historyImage, {
                                x:"-55%",
                                duration:3,
                                scrollTrigger: {
                                    trigger: card,
                                    start: "center center+=20%",
                                    scrub:1,
                                }
                            });
                        }
                    });
                    ScrollTrigger.matchMedia({
                        "(max-width: 575px)": () => {
                            const element = document.querySelector('.innumbers__image--wrapper');
                            const scrollPercentage = 25; // 初期スクロール位置のパーセンテージを設定
                            const scrollAmount = (element.scrollWidth - element.clientWidth) * (scrollPercentage / 100);
                            element.scrollLeft = scrollAmount;
                        }
                    });
                    

                    gsap.set(".point", {
                        autoAlpha: 0,
                    });
                    gsap.to(".point", {
                        autoAlpha: 1,
                        delay:0,
                        scrollTrigger: {
                            trigger: card,
                            start: "center center+=20%",
                            once: true
                        }
                    });
                    gsap.set(".line", {
                        autoAlpha: 0,
                    });
                    gsap.to(".line", {
                        autoAlpha: 1,
                        delay:0,
                        scrollTrigger: {
                            trigger: card,
                            start: "center center+=20%",
                            once: true
                        }
                    });
                    gsap.set(".year", {
                        y:10,
                        autoAlpha: 0,
                    });
                    gsap.to(".year", {
                        y:0,
                        autoAlpha: 1,
                        delay:0.2,
                        scrollTrigger: {
                            trigger: card,
                            start: "center center+=20%",
                            once: true
                        }
                    });
                    gsap.set(".suffix", {
                        y:10,
                        autoAlpha: 0,
                    });
                    gsap.to(".suffix", {
                        y:0,
                        autoAlpha: 1,
                        delay:0.4,
                        scrollTrigger: {
                            trigger: card,
                            start: "center center+=20%",
                            once: true
                        }
                    });
                    gsap.set(".data", {
                        y:10,
                        autoAlpha: 0,
                    });
                    gsap.to(".data", {
                        y:0,
                        autoAlpha: 1,
                        delay:0.6,
                        scrollTrigger: {
                            trigger: card,
                            start: "center center+=20%",
                            once: true
                        }
                    });
                }

                const bars = card.querySelectorAll('.innumbers__bar');
                bars.forEach(bar => {
                    const barHeight = parseInt(bar.getAttribute('data-bar')) * 4;

                    gsap.set(bar, {
                        height: 0,
                    });
                    gsap.to(bar, {
                        height: `${barHeight}px`,
                        scrollTrigger: {
                            trigger: card,
                            start: "center center+=20%",
                            once: true
                        }
                    });
                });

                const lottieIcos = card.querySelectorAll('.innumbers__ico');
                const lottieObjs = [];

                lottieIcos.forEach((lottieIco) => {
                    const lottiePath = lottieIco.getAttribute('data-numbers-ico');
                    const lottieObj = lottie.loadAnimation({
                        container: lottieIco,
                        renderer: 'svg',
                        loop: false,
                        autoplay: false,
                        path: "/dist/img/lottie/innumbers/" + lottiePath + ".json"
                    });

                    lottieObjs.push(lottieObj);
                });

                var countElements = card.querySelectorAll(".--data--value");
                countElements.forEach(function (countElement) {

                    let suffix = (countElement.getAttribute('data-fixed')) ? countElement.getAttribute('data-fixed') : 0;

                    gsap.to(countElement, {
                        scrollTrigger: {
                            trigger: card,
                            start: "center center+=20%",
                            once: true
                        },
                        innerHTML: parseFloat(countElement.getAttribute("data-value")).toFixed(suffix), // カウントアップの終了値
                        onUpdate: function () {
                            countUp(countElement, parseFloat(countElement.innerHTML));
                        }
                    });
                    function countUp(target, endValue) {
                        gsap.to(target, {
                            innerHTML: endValue.toFixed(suffix), // 表示する最終値
                            ease: "none", // アニメーションのイージング（指定しない場合はlinear）
                            onUpdate: function () {
                                target.innerHTML = parseFloat(target.innerHTML).toFixed(suffix); // アニメーション中の値も小数第一位まで表示
                            }
                        });
                    }
                });

                gsap.set(card, {
                    autoAlpha: 0,
                });

                gsap.to(card, {
                    autoAlpha: 1,
                    scrollTrigger: {
                        trigger: card,
                        start: "center center+=20%",
                        // markers: true,
                        onEnter: function () {
                            lottieObjs.forEach((lottieObj) => {
                                lottieObj.play();
                            });
                        }
                    },
                });
            });
        }
    }
}